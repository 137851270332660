import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import HeaderNavigation from '../HeaderNavigation/HeaderNavigation';

import styles from './Header.module.css';

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    document.addEventListener('click', (event) => {
      if (showMenu && !event.target.matches('.mobile-menu, .menu-item, .mobile-menu-button')) {
        setShowMenu(false);
      }
    });
  }, [showMenu]);

  const renderMenuContent = () => {
    return (
      <>
        <HeaderNavigation />
        <a
          href="/#contact-us"
          className={styles.contactUs}
        >
          Contact Us
        </a>
      </>
    );
  };

  return (
    <header className={styles.header}>
      <div className={styles.menuWrapper}>
        <Link to="/" className={styles.logo} />
        {renderMenuContent()}
      </div>
      <div className={cn(styles.mobileMenu, 'mobile-menu-button')} onClick={() => setShowMenu(!showMenu)} />
      <div className={cn(styles.mobileMenuWrapper, { [styles.opened]: showMenu }, 'mobile-menu')}>
        {renderMenuContent()}
      </div>
    </header>
  );
};

export default Header;
