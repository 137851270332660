import stratobellasImage from '../assets/images/stratobellas.png';
import testimonialImage from '../assets/images/testimonail-1.png';

export const testimonialsData = [{
  image: stratobellasImage,
  title: 'Sophia Tremblay',
  subTitle: 'Pharmacist',
  personImage: testimonialImage,
  description: `I've been using the Stratobella Lip Balm for a few weeks now, and I'm absolutely in love 
  with it! As someone who spends a lot of time outdoors, having sun protection for my lips is essential, and this 
  lip balm delivers on that front. The SPF protection gives me peace of mind knowing that my lips are shielded from 
  the sun's harmful rays.`,
}, {
  image: stratobellasImage,
  title: 'Charlotte Johnson',
  subTitle: 'Elementary School Teacher',
  personImage: testimonialImage,
  description: `I recently discovered the Stratobella Lip Balm, and it has quickly become a must-have in 
  my beauty routine. I love that it's made with natural ingredients because it gives me peace of mind knowing that 
  I'm not exposing my lips to harsh chemicals.`,
}];