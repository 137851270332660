import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { required, email } from 'redux-form-validators';

import { Input, Textarea } from '../FormsControls/FormControls';

import styles from './ContactUsForm.module.css';

export const contactUsName = 'contactUsForm';

const ContactUsForm = (props) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit} className={styles.form} id="contact-us">
      <div className={styles.fields}>
        <Field
          className={styles.input}
          component={Input}
          name="email"
          validate={[
            required(),
            email({ message: 'Please enter an email address' }),
          ]}
          placeholder="Email"
          label="Email"
        />
        <Field
          className={styles.input}
          component={Textarea}
          name="message"
          placeholder="Your message..."
          label="Message"
          validate={[required()]}
        />
      </div>
      <button className={styles.submit}>Send</button>
    </form>
  );
};

export default reduxForm({
  form: contactUsName,
})(ContactUsForm);
