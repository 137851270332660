import { reset } from 'redux-form';

import { contactUsName } from '../components/ContactUsForm/ContactUsForm';
import { contactUsAPI } from '../api/contactUs';
import { actionWrapper } from './actionWrapper';
import toast from 'react-hot-toast';

export const contactUsSubmit = (contactUsData) => actionWrapper(async (dispatch) => {
  await contactUsAPI.send(contactUsData);
  dispatch(reset(contactUsName));

  toast.success('Success', {
    position: 'bottom-right',
    duration: 3000,
  });
});