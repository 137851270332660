import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import HeaderNavigation from '../HeaderNavigation/HeaderNavigation';

import styles from './Footer.module.css';

const currentYear = moment().format('YYYY');

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Link to="/" className={styles.logo} />
      <div className={styles.navigationWrapper}>
        <HeaderNavigation />
      </div>
      <div className={styles.privacyLinks}>
        <Link to="/" className={styles.privacyLink}>
          Privacy policy
        </Link>
        <Link to="/" className={styles.privacyLink}>
          Terms of services
        </Link>
      </div>
      <div className={styles.copyright}>
        @2024 ALL RIGHTS RESERVED
      </div>
    </footer>
  );
};

export default Footer;