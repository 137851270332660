import * as _ from 'lodash';
import toast from 'react-hot-toast';

import { logout } from './auth';
import { isFetching } from './preloader';

export const actionWrapper = (action, errorCallback) => async (dispatch, getState) => {
  try {
    const newState = await action(dispatch, getState);
    if (newState) {
      dispatch(newState);
    }
  } catch (error) {
    dispatch(isFetching(false));
    const response = _.get(error, 'request.response');

    if (!response) {

      toast.error('Server error', {
        position: 'bottom-right',
        duration: 3000,
      });
    }

    try {
      const responseData = JSON.parse(response);

      if (responseData.error === 'jwt expired') {
        return dispatch(logout());
      }

      if (errorCallback) {
        errorCallback(responseData.error, dispatch);
      } else {
        const errorMessage = responseData.error || error.message || 'Server error';

        toast.error(errorMessage, {
          position: 'bottom-right',
          duration: 3000,
        });
      }
    } catch (parseError) {

      toast.error(error.message || 'Server error', {
        position: 'bottom-right',
        duration: 3000,
      });
    }
  }
};
