import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import ContactUsForm from '../../components/ContactUsForm/ContactUsForm';
import { contactUsSubmit } from '../../actions/contactUs';
import { testimonialsData } from '../../constants/testimonials';

import girlImage from '../../assets/images/girl.png';
import girl2Image from '../../assets/images/girl-2.png';
import substractImage from '../../assets/images/substruct-1.png';
import styles from './Dashboard.module.css';

const BluePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className={cn(styles.sliderPrevArrow)} onClick={onClick} />
  );
};

const BlueNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className={cn(styles.sliderNextArrow)} onClick={onClick} />
  );
};

const TestimonialsList = () => {
  return testimonialsData.map((testimonial, index) => (
    <div key={index} className={styles.testimonialBlock}>
      <div className={styles.testimonialLeftColumn}>
        <img className={styles.testimonialsImage} src={testimonial.image} alt="Testimonials"/>
      </div>
      <div className={styles.testimonialRightColumn}>
        <div className={styles.testimonialTop}>
          {/* <img className={styles.testimonialPersonImage} src={testimonial.personImage} alt="Testimonials"/> */}
          <div>
            <div className={styles.testimonailTitle}>
              {testimonial.title}
            </div>
            <div className={styles.testimonailSubTitle}>
              {testimonial.subTitle}
            </div>
          </div>
        </div>
        <div
          className={styles.testimonalDescription}
          dangerouslySetInnerHTML={{ __html: testimonial.description }}
        />
      </div>
    </div>
  ));
};

const Dashboard = (props) => {
  const { contactUsSubmit } = props;

  const onSubmit = (contactUsData) => {
    contactUsSubmit(contactUsData);
  };

  const renderNaturalProduct = () => (
    <>
      <div className={cn(styles.smallBoldFont, 'mt-10')}>
        100% NATURAL PRODUCTS
      </div>
      <div className={cn(styles.smallBoldFont, 'mt-10')}>
        Ingredients:
      </div>
      <div className={styles.smallLightFont}>
        Zinc Oxide 20%
      </div>
      <div className={cn(styles.smallBoldFont, 'mt-20')}>
        Non-Medicinal ingredients:
      </div>
      <div className={styles.smallLightFont}>
        Beeswax, butyrospermum, parkii, (shea), butter, caprylic, triglyceride, cocos nucifera (coconut) oil,
        copernicia cerifera (carnauba) wax, euphorbia cerifera (candelilla) wax,<br />
        isoamyl cocoate, persea gratissima (avocado) oil,<br />
        ricinus communis (castor) seed oil,<br />
        simmondsia chinensis (jojoba) seed oil, tocopherol
      </div>
    </>
  );

  const renderProjectsSlider = () => {
    const sliderConfig = {
      dots: false,
      slidesToShow: 1,
      prevArrow: <BluePrevArrow />,
      nextArrow: <BlueNextArrow />,
      autoplay: false,
    };

    return (
      <Slider
        infinite
        autoplay
        slidesToScroll={1}
        {...sliderConfig}
      >
        {TestimonialsList()}
      </Slider>
    );
  };

  const renderNaturalProductTitle = () => (
    <div className={styles.middleLightFont}>
      100%  natural products<br />
      Made in canada
    </div>
  );

  const renderGirlImage = () => <img className={styles.girlImage} src={girlImage} alt="Girl"/>;

  return (
    <div className={styles.wrapper}>
      <section className={styles.banner}>
        <div className={styles.bannerTitle}>
          Stratobella
        </div>
        <div className={styles.bannerDescription}>
          Stratobella Lip Balm is your natural solution for sun protection and lip care. Made with love in 
          Canada, our SPF-infused formula harnesses the power of nature to shield your lips from harmful UV rays while 
          keeping them soft, smooth, and hydrated.
        </div>
      </section>
      <section className={styles.productSection} id="product">
        <div className={styles.productDesktop}>
          <div className={styles.productLeftRow}>
            <div className={styles.bigFont}>
              Product
            </div>
            <div className={styles.blueLine}/>
            {renderNaturalProduct()}
          </div>
          <div className={styles.productMiddleRow}>
            {renderGirlImage()}
          </div>
          <div className={styles.productRightRow}>
            {renderNaturalProductTitle()}
            <div className={styles.blueLine}/>
            <div className={cn(styles.smallLightFont, 'mt-20')}>
              Soft Natural Tinted SPF 30 Lip Balm is<br />
              fragrance free, made without harmful<br />
              chemicals, artificial fragrances or colours.
            </div>
            <img src={substractImage} alt="Substract" className={styles.stratobellaExplore} />
          </div>
        </div>
        <div className={styles.productMobile}>
          <div className={styles.bigFont}>
            Product
          </div>
          {renderGirlImage()}
          {renderNaturalProductTitle()}
          {renderNaturalProduct()}
        </div>
      </section>
      <section className={styles.aboutSection} id="about">
        <div className={styles.bigFont}>
          About product
        </div>
        <div className={styles.aboutContent}>
          <div className={styles.aboutLeftRow}>
            <div className={styles.middleBoldFont}>
              All natural compounds
            </div>
            <div className={styles.blueLine}/>
            <div className={styles.naturalDescription}>
              <div>
                Protects against harmful UV rays (SPF 30)
              </div>
              <div>
                When applied lip balm is skin colour
              </div>
              <div>
                Proudly all made in Canada
              </div>
            </div>
          </div>
          <div className={styles.aboutMiddleRow}>
            <img className={styles.girlTwoImage} src={girl2Image} alt="Image" />
          </div>
          <div className={styles.aboutRightRow}>
            <div>
              Introducing "Stratobella Lip Balm" — a luxurious blend of natural ingredients designed 
              to pamper and protect your lips. Infused with SPF technology, this lip balm provides reliable 
              defense against harmful UV rays while deeply moisturizing and nourishing your lips. Enriched with 
              botanical extracts and vitamins, including shea butter and jojoba oil, Stratobella Lip Balm 
              replenishes moisture, leaving your lips soft, smooth, and irresistibly kissable. Experience the ultimate 
              lip care with Stratobella Lip Balm.
            </div>
          </div>
        </div>
      </section>
      <section className={styles.testimonialsSection} id="testimonials">
        <div className={styles.bigFont}>
          Testimonials
        </div>
        <div className={styles.testimonialSlider}>
          {renderProjectsSlider()}
        </div>
      </section>
      <section className={styles.connectedSection}>
        <div className={styles.bigFont}>
          Let’s stay connected
        </div>
        <ContactUsForm onSubmit={onSubmit} />
      </section>
    </div>
  );
};

const mapDispatchToProps = {
  contactUsSubmit,
};

export default connect(null, mapDispatchToProps)(Dashboard);