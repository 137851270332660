import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import ScrollToTop from '../../hoc/ScrollToTop';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Preloader from '../../components/Preloader/Preloader';
import Dashboard from '../Dashboard/Dashboard';
import PageNotFound from '../PageNotFound/PageNotFound';

import styles from './App.module.css';

function App (props) {
  const { isFetching } = props;

  const renderCommonRoutes = () => (
    <Switch>
      <Route path="*" component={PageNotFound}/>
    </Switch>
  );

  return (
    <div className={styles.wrapper}>
      {isFetching && <Preloader/>}
      <Toaster />
      <ScrollToTop />
      <Header />
      <Switch>
        <Route exact path="/" component={Dashboard} />
        {renderCommonRoutes()}
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
