const siteURL = 'http://localhost:3000';

export default {
  siteURL,
  backURL: 'https://stratobella.com/',
};




