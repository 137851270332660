import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './HeaderNavigation.module.css';

const HeaderNavigation = () => (
  <nav>
    <ul className={styles.menu}>
      <li className="menu-item">
        <a
          href="/#product"
          className={styles.menuLink}
        >
          About Us
        </a>
      </li>
      <li className="menu-item">
        <a
          href="/#about"
          className={styles.menuLink}
        >
          About Product
        </a>
      </li>
      <li className="menu-item">
        <a
          href="/#testimonials"
          className={styles.menuLink}
        >
          Testimonials
        </a>
      </li>
    </ul>
  </nav>
);

export default HeaderNavigation;
