import React from 'react';
import cn from 'classnames';

import styles from './FormControls.module.css';

export const FormControl = (props) => {
  const {
    label,
    meta,
    children,
    helpText,
    wrapperClassName,
    withErrorSign,
  } = props;

  const labelUp = (e) => {
    e.target.parentNode.querySelector('.form_control').focus();
  };

  return (
    <div className={cn(styles.formGroup, wrapperClassName)}>
      <div className="input_wrapper">
        {label && (
          <label
            className={`label ${
              meta.active || meta.dirty || meta.initial || meta.initial === 0 ? 'active' : ''
            }`}
            onClick={labelUp}
          >
            {label}
          </label>
        )}

        {children}
      </div>

      {helpText && (
        <div className={cn(styles.input_help, 'help_text')}>{helpText}</div>
      )}

      {meta.touched && meta.error && (
        <div className={cn(styles.validationMessage, 'validation_message')}>{meta.error}</div>
      )}
      {withErrorSign && meta.touched && meta.error && <span className="validation_status_error" />}
      {meta.visited && meta.valid && <span className="validation_status_ok" />}
    </div>
  );
};

export const Input = (props) => {
  const { input, meta, placeholder, type } = props;

  const validationClass = meta.touched && meta.error ? 'validation_error' : 'validation_ok';

  return (
    <FormControl {...props}>
      <input
        {...input}
        type={type}
        placeholder={placeholder}
        className={`form_control ${props.className} ${validationClass}`}
      />
    </FormControl>
  );
};

const SelectWrapper = (props) => {
  const { input, meta, placeholder, children } = props;

  return (
    <FormControl {...props}>
      <select
        {...input}
        className={cn('form_control', props.className, {
          validation_error: meta.visited && meta.error,
          validation_ok: meta.visited && !meta.error,
        })}
      >
        {placeholder && <option value="" disabled hidden>{placeholder}</option>}
        <option/>
        {children}
      </select>
    </FormControl>
  );

};

export const SelectInput = (props) => (
  <SelectWrapper {...props}>
    {props.options && props.options.map((item, index) => (
      <option value={item} key={index}>
        {item}
      </option>
    ))}
  </SelectWrapper>
);

export const Textarea = (props) => {
  const { input, meta, ...restProps } = props;

  return (
    <FormControl {...props}>
      <textarea
        {...input}
        {...restProps}
        className={cn(
          props.className || 'form_control',
          props.input_design,
          meta.touched && meta.error ? 'validation_error' : 'validation_ok'
        )}
      />
    </FormControl>
  );
};


export const Checkbox = (props) => {
  const { input, meta, ...restProps } = props;

  return (
    <div className={`form_group ${styles.checkbox_form_group} ${props.label_type} ${props.input_wrapper_class}`}>
      {props.label && (
        <label
          htmlFor={props.id}
          className={`input_wrapper ${styles.input_wrapper_checkbox} ${props.className} ${
            meta.active || meta.dirty || meta.initial ? 'active' : ''
          }`}
        >
          <input
            {...input}
            {...restProps}
            type="checkbox"
            className={`form_control ${styles.form_control_checkbox} ${props.input_design} ${props.className}`}
          />
          <span className={styles.checkmark} />
          {props.label}
        </label>
      )}

      {props.helpText && (
        <div className={`${styles.input_help} ${props.helpTextDesign}`}>{props.helpText}</div>
      )}

      {meta.touched && meta.error && <div className="validation_message">{meta.error}</div>}
    </div>
  );
};
